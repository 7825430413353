import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import TemporaryDrawer from './SideBar';
import AuthLogin from '../auth/authLogin';
import AuthLogout from '../auth/authLogout';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";


export default function ButtonAppBar() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" >
<Toolbar sx={{justifyContent: "space-between" }}>
        <Toolbar sx={{float: "left", width: "150px", left: "-20px"}}>
        { isAuthenticated ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          ) : <></> }
          </Toolbar>

          <Toolbar sx={{display: "flex", justifyContent: "center", width: "150px"}}>
          <Typography variant="h7" >
            DataBridge Input
          </Typography>
          </Toolbar>
          <Toolbar sx={{justifyContent: "center"}}>
<div><Button sx={{textTransform: 'lowercase'}}  color="inherit">{accounts[0]?.username}</Button></div>

          { isAuthenticated ? <AuthLogout></AuthLogout> : <AuthLogin></AuthLogin> }

          </Toolbar>
        </Toolbar>
      </AppBar>
      <TemporaryDrawer state={state} setState={setState} toggleDrawer={toggleDrawer}></TemporaryDrawer>
    </Box>
  );
}
/*
          <Typography variant="h7" color="inherit" sx={{backgroundColor: 'clear'}}>{accounts[0]?.username}</Typography>
          */