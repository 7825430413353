import { useState, useEffect } from "react"
import "../pages/table.css"
import { vaccines_cvx } from "../vaccines"

export const TableCell = ({ getValue, row, column, table }) => {
    const initialValue = getValue()
    const columnMeta = column.columnDef.meta
    const tableMeta = table.options.meta
    const [value, setValue] = useState(initialValue)
    const [validationMessage, setValidationMessage] = useState("");

    //console.log("TEABLE:", table.getAllColumns());
    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
    
    const displayValidationMessage = (e) => {
      if (columnMeta && columnMeta.validate) {
        const isValid = columnMeta.validate(e.target.value);
        if (isValid) {
          e.target.setCustomValidity("");
          setValidationMessage("");
        } else {
          e.target.setCustomValidity(columnMeta.validationMessage);
          setValidationMessage(columnMeta.validationMessage);
        }
      } else if (e.target.validity.valid) {
        setValidationMessage("");
      } else {
        setValidationMessage(e.target.validationMessage);
      }
    };
    
    const onBlur = (e) => {
      displayValidationMessage(e);
      //console.log(e.target.validity.valid);
      tableMeta?.updateData(row.index, column.id, value, e.target.validity.valid);
    }

    const onSelectChange = e => {

      console.log("VALUE", e.target.value)
      if (e.target.value == "Please Select ...") {
        console.log("HERERE",e.target.validity.valid);
        setValue("");
        e.target.setCustomValidity("Please select a choice from dropdown");
        displayValidationMessage(e);
      }
      else
      {
        setValue(e.target.value)
        e.target.setCustomValidity("");
      }

      //console.log("ALSO", columnMeta?.alsoupdate)

      if (columnMeta?.alsoupdate) {
        var index = e.nativeEvent.target.selectedIndex;

        console.log(index, e.target.value)
        console.log(row.index, column.id, e.nativeEvent.target[index].text)
        if (index > 0) {
        tableMeta?.updateData(row.index, column.id, e.nativeEvent.target[index].text, e.target.validity.valid)
        tableMeta?.updateData(row.index, columnMeta?.alsoupdate, vaccines_cvx[index-1], e.target.validity.valid)
        }
        else
        {
          tableMeta?.updateData(row.index, column.id, "", e.target.validity.valid)
          tableMeta?.updateData(row.index, columnMeta?.alsoupdate, "", e.target.validity.valid)
        }
        //tableMeta?.updateData(row.index, columnMeta?.alsoupdate, e.target.value)

      }
      else {
        tableMeta?.updateData(row.index, column.id, e.target.value, e.target.validity.valid)
      }
    }

    let editSize = 10
    if (column.columnDef?.size !== 150) {
      console.log("COLSIZE", column.columnDef?.size)
      editSize = column.columnDef?.size
    }

    const [currentValue, setCurrentValue] = useState(initialValue);
  
    function checkValue(event) {
      let hh = handleDecimalsOnValue(event.target.value);
      console.log(columnMeta?.maxValue)
      if (hh <= columnMeta?.maxValue) {
        console.log("less than")
        setCurrentValue(hh); //value to store for the control itself
        setValue(hh); //value to store in json
      }

      //setCurrentValue(handleDecimalsOnValue(event.target.value));
    }
    
    function handleDecimalsOnValue(value) {
        //eslint-disable-next-line
        const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }

    //console.log("TABLROW-EDITED", tableMeta?.editedRows[row.id])
    const [inputValue, setInputValue] = useState(initialValue); 
  
    const handleChange = (e) => { 
        // Allow only numbers 
        console.log(e.target);
        const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
          
        setInputValue(numericValue); 
        setValue(numericValue);
    }; 

    if (tableMeta?.editedRows[row.id]) {
      if (columnMeta?.type === "select") {
        return (
        <select 
        onChange={onSelectChange} 
        required={columnMeta?.required} 
        title={validationMessage} 
        value={initialValue}>
          
          {columnMeta?.options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
          
        </select>)
      }
      if (columnMeta?.type === "number") {
        return (<input 
        type="text"
        onChange={handleChange} 
        value={inputValue} 
        keyboardtype="numeric"
        size={editSize}
        onBlur={onBlur}
        required={true}
        title={validationMessage}
        maxLength={9}
    /> )
      }
      else if (columnMeta?.type === "decimal") {
        return (<input 
        type="text"
        value={currentValue}
        onChange={(event) => checkValue(event, 'change')}
        onBlur={onBlur}
        size={editSize}
        required={true}
        title={validationMessage}
    />)
      }
      else {
        return (<input
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={onBlur}
          type={columnMeta?.type || "text"}
          size={editSize}
          maxLength={10}
          readOnly={columnMeta?.readOnly ? true : false} 
          required={true}
        />)
      }
    }

    return <span>{value}</span>
  }