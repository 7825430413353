export const qsiprovidercodes = [
    { value: '', label: "Please Select ..."},
    { label: 'AMB' },
    { label: 'ANE' },
    { label: 'CARD' },
    { label: 'CD' },
    { label: 'DME' },
    { label: 'DN' },
    { label: 'ENDO' },
    { label: 'FAC' },
    { label: 'GAST' },
    { label: 'GYN' },
    { label: 'HH' },
    { label: 'INFD' },
    { label: 'LAB' },
    { label: 'MHN' },
    { label: 'MHP' },
    { label: 'NEPH' },
    { label: 'NPCP' },
    { label: 'OB' },
    { label: 'OTHR' },
    { label: 'PCP' },
    { label: 'PNC' },
    { label: 'RAD' },
    { label: 'RN' },
    { label: 'RPH' },
    { label: 'UC' },
    { label: 'VC' },
]