export const specialtycodes = [
    { value: '', label: 'Please Select ...'},
    { label: 'ABA-APPLIED BEHAVIORAL ANALYST' },
    { label: 'ABA-GROUP OF PROVIDERS' },
    { label: 'ABA-PHYSICAL THERAPIST' },
    { label: 'ABA-PHYSICIAN' },
    { label: 'ADL-CERTIFIED REGISTERED NURSE ANESTHETIST' },
    { label: 'ADL-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'ADL-PHYSICIAN' },
    { label: 'ADL-PHYSICIAN ASSISTANT' },
    { label: 'ADL-REGISTERED NURSE PRACTITIONER' },
    { label: 'ADM-ANCILLARY' },
    { label: 'ADM-PHYSICIAN' },
    { label: 'A-GROUP OF PROVIDERS' },
    { label: 'AI-PHYSICIAN' },
    { label: 'AI-REGISTERED NURSE PRACTITIONER' },
    { label: 'AN-AUDIOLOGIST' },
    { label: 'AN-CERTIFIED REGISTERED NURSE ANESTHETIST' },
    { label: 'AN-GROUP OF PROVIDERS' },
    { label: 'AN-OSTEOPATH' },
    { label: 'AN-PEDIATRIC ANESTHESIA' },
    { label: 'AN-PHYSICIAN' },
    { label: 'AN-PHYSICIAN ASSISTANT' },
    { label: 'AN-REGISTERED NURSE PRACTITIONER' },
    { label: 'A-PHYSICIAN' },
    { label: 'APM-PHYSICIAN' },
    { label: 'APM-PHYSICIAN ASSISTANT' },
    { label: 'APM-REGISTERED NURSE PRACTITIONER' },
    { label: 'AR-PHYSICIAN' },
    { label: 'ATP-GROUP OF PROVIDERS' },
    { label: 'ATP-PHYSICIAN' },
    { label: 'AUD-AUDIOLOGIST' },
    { label: 'AUD-DOCTOR OF AUDIOLOGY' },
    { label: 'AUD-GROUP OF PROVIDERS' },
    { label: 'AUD-PHYSICIAN' },
    { label: 'AUD-SPEECH/HEARING THERAPIST' },
    { label: 'BBK-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'BBK-MASTER OF SCIENCE IN NURSING' },
    { label: 'BBK-PHYSICIAN' },
    { label: 'CCA-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'CCA-PHYSICIAN' },
    { label: 'CCA-PHYSICIAN ASSISTANT' },
    { label: 'CCM-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'CCM-GROUP OF PROVIDERS' },
    { label: 'CCM-OSTEOPATH' },
    { label: 'CCM-PHYSICIAN' },
    { label: 'CCM-PHYSICIAN ASSISTANT' },
    { label: 'CCM-REGISTERED NURSE PRACTITIONER' },
    { label: 'CCP-PHYSICIAN' },
    { label: 'CCP-PHYSICIAN SPECIALIST PCP' },
    { label: 'CCP-REGISTERED NURSE PRACTITIONER' },
    { label: 'CCS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'CCS-PHYSICIAN' },
    { label: 'CCS-PHYSICIAN ASSISTANT' },
    { label: 'CCS-REGISTERED NURSE PRACTITIONER' },
    { label: 'CD-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'CD-GROUP OF PROVIDERS' },
    { label: 'CD-PHYSICIAN' },
    { label: 'CD-REGISTERED NURSE PRACTITIONER' },
    { label: 'CDS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'CDS-GROUP OF PROVIDERS' },
    { label: 'CDS-PHYSICIAN' },
    { label: 'CG-PHYSICIAN' },
    { label: 'CHN-MASTER OF SCIENCE IN NURSING' },
    { label: 'CHN-OSTEOPATH' },
    { label: 'CHN-PHYSICIAN' },
    { label: 'CHP-OSTEOPATH' },
    { label: 'CHP-PHYSICIAN' },
    { label: 'CHP-PSYCHOLOGIST' },
    { label: 'CI-CHIROPRACTOR' },
    { label: 'CLP-PHYSICIAN' },
    { label: 'CN-PHYSICIAN' },
    { label: 'CN-PSYCHOLOGIST' },
    { label: 'CRS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'CRS-PHYSICIAN' },
    { label: 'DAY-ANCILLARY' },
    { label: 'DBP-EARLY INTERVENTION' },
    { label: 'DBP-PHYSICIAN' },
    { label: 'DBP-REGISTERED NURSE PRACTITIONER' },
    { label: 'DIA-OSTEOPATH' },
    { label: 'DIL-PHYSICIAN' },
    { label: 'DME-ANCILLARY' },
    { label: 'DME-DME SUPPLIER' },
    { label: 'DMP-PHYSICIAN' },
    { label: 'DN-DENTIST' },
    { label: 'DN-GROUP OF PROVIDERS' },
    { label: 'DN-PHYSICIAN' },
    { label: 'D-OSTEOPATH' },
    { label: 'D-PHYSICIAN' },
    { label: 'D-PHYSICIAN ASSISTANT' },
    { label: 'DR-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'DR-GROUP OF PROVIDERS' },
    { label: 'DR-OSTEOPATH' },
    { label: 'DR-PHYSICIAN' },
    { label: 'EM-EMERGENCY TRANSPORTATION' },
    { label: 'EM-GROUP OF PROVIDERS' },
    { label: 'EM-HOSPITAL' },
    { label: 'EM-OSTEOPATH' },
    { label: 'EM-PHYSICIAN' },
    { label: 'EM-PHYSICIAN ASSISTANT' },
    { label: 'EM-REGISTERED NURSE PRACTITIONER' },
    { label: 'END-GROUP OF PROVIDERS' },
    { label: 'END-OSTEOPATH' },
    { label: 'END-PHYSICIAN' },
    { label: 'END-PHYSICIAN ASSISTANT' },
    { label: 'END-REGISTERED NURSE PRACTITIONER' },
    { label: 'ETX-PHYSICIAN' },
    { label: 'FNP-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'FNP-LICENSED PRACTICAL NURSE' },
    { label: 'FNP-LICENSED PROFESSIONAL COUNSELOR' },
    { label: 'FNP-MASTER OF SCIENCE IN NURSING' },
    { label: 'FNP-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'FNP-PHYSICIAN' },
    { label: 'FNP-REGISTERED NURSE PRACTITIONER' },
    { label: 'FP-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'FPG-PHYSICIAN' },
    { label: 'FP-GROUP OF PROVIDERS' },
    { label: 'FP-OSTEOPATH' },
    { label: 'FP-PHYSICIAN' },
    { label: 'FP-PHYSICIAN ASSISTANT' },
    { label: 'FP-PHYSICIAN SPECIALIST PCP' },
    { label: 'FP-PODIATRIST' },
    { label: 'FP-REGISTERED NURSE (PRIVATE NURSE)' },
    { label: 'FP-REGISTERED NURSE PRACTITIONER' },
    { label: 'FPS-PHYSICIAN' },
    { label: 'FQHC' },
    { label: 'FSM-PHYSICIAN' },
    { label: 'GE-AUDIOLOGIST' },
    { label: 'GE-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'GE-GROUP OF PROVIDERS' },
    { label: 'GE-OSTEOPATH' },
    { label: 'GE-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'GE-PHYSICIAN' },
    { label: 'GE-PHYSICIAN ASSISTANT' },
    { label: 'GE-REGISTERED NURSE PRACTITIONER' },
    { label: 'GN-PHYSICIAN' },
    { label: 'GN-PSYCHOLOGIST' },
    { label: 'GO-PHYSICIAN' },
    { label: 'GO-REGISTERED NURSE PRACTITIONER' },
    { label: 'GPM-PHYSICIAN' },
    { label: 'GP-PHYSICIAN' },
    { label: 'GR-PHYSICIAN' },
    { label: 'GR-PHYSICIAN ASSISTANT' },
    { label: 'GR-REGISTERED NURSE PRACTITIONER' },
    { label: 'GS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'GS-OSTEOPATH' },
    { label: 'GS-PHYSICIAN' },
    { label: 'GS-PHYSICIAN ASSISTANT' },
    { label: 'GS-REGISTERED NURSE PRACTITIONER' },
    { label: 'GYN-PHYSICIAN' },
    { label: 'HEM-OSTEOPATH' },
    { label: 'HEM-PHYSICIAN' },
    { label: 'HEM-REGISTERED NURSE PRACTITIONER' },
    { label: 'HEP-PHYSICIAN' },
    { label: 'HHC-ANCILLARY' },
    { label: 'HHC-GROUP OF PROVIDERS' },
    { label: 'HHC-HOME CARE' },
    { label: 'HHC-HOME HEALTH AGENCY' },
    { label: 'HHC-HOME HEALTH AIDE' },
    { label: 'HHC-PERSONAL CARE ATTENDANT' },
    { label: 'HHC-SERVICE LOCATION' },
    { label: 'HIT-ANCILLARY' },
    { label: 'HIT-DME SUPPLIER' },
    { label: 'HIT-GROUP OF PROVIDERS' },
    { label: 'HMP-PHYSICIAN' },
    { label: 'HO-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'HOP-HOSPICE' },
    { label: 'HO-PHYSICIAN' },
    { label: 'HOP-PHYSICIAN' },
    { label: 'HOP-SERVICE LOCATION' },
    { label: 'HO-REGISTERED NURSE PRACTITIONER' },
    { label: 'HOS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'HOS-GROUP OF PROVIDERS' },
    { label: 'HOS-OSTEOPATH' },
    { label: 'HOS-PHYSICIAN' },
    { label: 'HOS-PHYSICIAN ASSISTANT' },
    { label: 'HOS-REGISTERED NURSE PRACTITIONER' },
    { label: 'HSO-PHYSICIAN' },
    { label: 'HS-PHYSICIAN' },
    { label: 'ICE-PHYSICIAN' },
    { label: 'ICE-REGISTERED NURSE PRACTITIONER' },
    { label: 'IC-PHYSICIAN' },
    { label: 'ID-MASTER OF SCIENCE IN NURSING' },
    { label: 'ID-OSTEOPATH' },
    { label: 'ID-PHYSICIAN' },
    { label: 'ID-PHYSICIAN ASSISTANT' },
    { label: 'ID-PHYSICIAN SPECIALIST PCP' },
    { label: 'ID-REGISTERED NURSE PRACTITIONER' },
    { label: 'ILI-PHYSICIAN' },
    { label: 'IM-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'IMG-PHYSICIAN' },
    { label: 'IM-GROUP OF PROVIDERS' },
    { label: 'IM-HOSPITAL' },
    { label: 'IM-OCCUPATIONAL THERAPIST' },
    { label: 'IM-OSTEOPATH' },
    { label: 'IM-PHYSICIAN' },
    { label: 'IM-PHYSICIAN ASSISTANT' },
    { label: 'IM-PHYSICIAN SPECIALIST PCP' },
    { label: 'IM-REGISTERED NURSE PRACTITIONER' },
    { label: 'ISM-PHYSICIAN' },
    { label: 'LC-APPLIED BEHAVIORAL ANALYST' },
    { label: 'LC-GROUP OF PROVIDERS' },
    { label: 'LC-LICENSED PRACTICAL NURSE' },
    { label: 'LC-LICENSED PROFESSIONAL COUNSELOR' },
    { label: 'LC-MENTAL HEALTH RESIDENTIAL TREATMENT CENTER' },
    { label: 'LC-PHYSICIAN' },
    { label: 'LC-PSYCHOLOGIST' },
    { label: 'LC-SOCIAL WORKER' },
    { label: 'LM-PHYSICIAN' },
    { label: 'MFM-GROUP OF PROVIDERS' },
    { label: 'MFM-PHYSICIAN' },
    { label: 'MFW-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'MFW-NURSE MIDWIFE' },
    { label: 'MFW-PHYSICIAN' },
    { label: 'MFW-REGISTERED NURSE PRACTITIONER' },
    { label: 'MG-PHYSICIAN' },
    { label: 'MSC002064970-PHYSICIAN' },
    { label: 'MSC002064970-PSYCHOLOGIST' },
    { label: 'MS-GROUP OF PROVIDERS' },
    { label: 'MS-SERVICE LOCATION' },
    { label: 'MSW-DOCTOR OF SOCIAL WORK' },
    { label: 'MSW-GROUP OF PROVIDERS' },
    { label: 'MSW-LICENSED PROFESSIONAL COUNSELOR' },
    { label: 'MSW-PSYCHIATRIST' },
    { label: 'MSW-SOCIAL WORKER' },
    { label: 'NA-GROUP OF PROVIDERS' },
    { label: 'NA-PHYSICIAN' },
    { label: 'NA-REGISTERED NURSE PRACTITIONER' },
    { label: 'N-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'NDN-PHYSICIAN' },
    { label: 'NEP-DIALYSIS' },
    { label: 'NEP-GROUP OF PROVIDERS' },
    { label: 'NEP-LABORATORY' },
    { label: 'NEP-OSTEOPATH' },
    { label: 'NEP-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'NEP-PHYSICIAN' },
    { label: 'NEP-REGISTERED NURSE PRACTITIONER' },
    { label: 'NM-PHYSICIAN' },
    { label: 'NO-PHYSICIAN' },
    { label: 'N-OSTEOPATH' },
    { label: 'N-PHYSICIAN' },
    { label: 'NPM-NEONATAL NURSE PRACTITIONER' },
    { label: 'NPM-OSTEOPATH' },
    { label: 'NPM-PHYSICIAN' },
    { label: 'NPM-PHYSICIAN ASSISTANT' },
    { label: 'NPM-REGISTERED NURSE PRACTITIONER' },
    { label: 'NP-PHYSICIAN' },
    { label: 'NP-REGISTERED NURSE PRACTITIONER' },
    { label: 'N-PSYCHIATRIST' },
    { label: 'N-PSYCHOLOGIST' },
    { label: 'N-REGISTERED NURSE PRACTITIONER' },
    { label: 'NRN-PHYSICIAN' },
    { label: 'NR-PHYSICIAN' },
    { label: 'NS-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'NS-PHYSICIAN' },
    { label: 'NS-PHYSICIAN ASSISTANT' },
    { label: 'NSP-PHYSICIAN' },
    { label: 'NS-REGISTERED NURSE PRACTITIONER' },
    { label: 'NTR-GROUP OF PROVIDERS' },
    { label: 'NTR-NUTRITIONIST' },
    { label: 'NTR-REGISTERED DIETICIAN' },
    { label: 'OBG-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'OBG-GROUP OF PROVIDERS' },
    { label: 'OBG-NURSE MIDWIFE' },
    { label: 'OBG-OSTEOPATH' },
    { label: 'OBG-PHYSICIAN' },
    { label: 'OBG-REGISTERED NURSE PRACTITIONER' },
    { label: 'OBS-PHYSICIAN' },
    { label: 'OFS-DENTIST' },
    { label: 'OFS-PHYSICIAN' },
    { label: 'OM-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'OM-GROUP OF PROVIDERS' },
    { label: 'OMM-PHYSICIAN' },
    { label: 'OM-OCCUPATIONAL THERAPIST' },
    { label: 'OMO-PHYSICIAN' },
    { label: 'OM-PHYSICIAN' },
    { label: 'ON-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'ON-MASTER OF SCIENCE IN NURSING' },
    { label: 'ON-PHYSICIAN' },
    { label: 'ON-REGISTERED NURSE PRACTITIONER' },
    { label: 'OPH-ANCILLARY' },
    { label: 'OPH-GROUP OF PROVIDERS' },
    { label: 'OPH-OPTOMETRIST' },
    { label: 'OPH-PHYSICIAN' },
    { label: 'OP-PHYSICIAN' },
    { label: 'OPT-ANCILLARY' },
    { label: 'OPT-GROUP OF PROVIDERS' },
    { label: 'OPT-OPTOMETRIST' },
    { label: 'OPT-PHYSICIAN' },
    { label: 'OPT-VISION CENTER' },
    { label: 'ORS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'ORS-MASTER OF SCIENCE IN NURSING' },
    { label: 'ORS-PHYSICIAN' },
    { label: 'ORS-PHYSICIAN ASSISTANT' },
    { label: 'ORS-REGISTERED NURSE PRACTITIONER' },
    { label: 'OS-ANCILLARY' },
    { label: 'OS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'OS-DENTIST' },
    { label: 'OS-DOCTOR OF PHYSICAL THERAPY' },
    { label: 'OS-GROUP OF PROVIDERS' },
    { label: 'OS-MENTAL HEALTH CLINIC' },
    { label: 'OSM-PHYSICIAN' },
    { label: 'OS-OCCUPATIONAL THERAPIST' },
    { label: 'OS-OPTOMETRIST' },
    { label: 'OS-PHYSICAL THERAPIST' },
    { label: 'OS-PHYSICIAN' },
    { label: 'OS-REGISTERED BEHAVIOR TECHNICIAN' },
    { label: 'OS-SERVICE LOCATION' },
    { label: 'OT-APPLIED BEHAVIORAL ANALYST' },
    { label: 'OT-GROUP OF PROVIDERS' },
    { label: 'OT-MASTERS OF SCIENCE' },
    { label: 'OT-OCCUPATIONAL THERAPIST' },
    { label: 'OTO-DOCTOR OF AUDIOLOGY' },
    { label: 'OTO-GROUP OF PROVIDERS' },
    { label: 'OTO-PHYSICIAN' },
    { label: 'OTO-PHYSICIAN ASSISTANT' },
    { label: 'OT-OPTOMETRIST' },
    { label: 'OT-PHYSICAL THERAPIST' },
    { label: 'OT-PHYSICIAN' },
    { label: 'OTR-PHYSICIAN' },
    { label: 'PAC-PHYSICIAN' },
    { label: 'PAN-ANCILLARY' },
    { label: 'PAN-PEDIATRIC ANESTHESIA' },
    { label: 'PAN-PHYSICIAN' },
    { label: 'PA-PHYSICIAN ASSISTANT' },
    { label: 'PCC-PHYSICIAN' },
    { label: 'P-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PCO-GROUP OF PROVIDERS' },
    { label: 'PCO-PHYSICIAN' },
    { label: 'PCO-PSYCHOLOGIST' },
    { label: 'PCP-PHYSICIAN' },
    { label: 'PCS-PHYSICIAN' },
    { label: 'PCS-REGISTERED NURSE PRACTITIONER' },
    { label: 'PCT-PHYSICIAN' },
    { label: 'PDA-PHYSICIAN' },
    { label: 'PDC-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PD-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PD-CLINICAL NURSE SPECIALIST' },
    { label: 'PDC-PHYSICIAN' },
    { label: 'PDD-PHYSICIAN' },
    { label: 'PDE-HOSPITAL' },
    { label: 'PDE-PHYSICIAN' },
    { label: 'PD-GROUP OF PROVIDERS' },
    { label: 'PDI-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PDI-OSTEOPATH' },
    { label: 'PDI-PHYSICIAN' },
    { label: 'PD-MASTER OF SCIENCE IN NURSING' },
    { label: 'PDO-PHYSICIAN' },
    { label: 'PD-OSTEOPATH' },
    { label: 'PD-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'PD-PHYSICIAN' },
    { label: 'PD-PHYSICIAN SPECIALIST PCP' },
    { label: 'PDP-PHYSICIAN' },
    { label: 'PD-PSYCHOLOGIST' },
    { label: 'PD-REGISTERED NURSE PRACTITIONER' },
    { label: 'PD-REHABILITATION CENTER' },
    { label: 'PDR-PHYSICIAN' },
    { label: 'PDS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PDS-GROUP OF PROVIDERS' },
    { label: 'PDS-PHYSICIAN' },
    { label: 'PDS-REGISTERED NURSE PRACTITIONER' },
    { label: 'PEM-PHYSICIAN' },
    { label: 'PEM-PHYSICIAN ASSISTANT' },
    { label: 'PE-PHYSICIAN' },
    { label: 'PE-PHYSICIAN ASSISTANT' },
    { label: 'PE-REGISTERED NURSE PRACTITIONER' },
    { label: 'PG-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PG-PHYSICIAN' },
    { label: 'PG-REGISTERED NURSE PRACTITIONER' },
    { label: 'P-GROUP OF PROVIDERS' },
    { label: 'PHD-CLINICAL NURSE SPECIALIST' },
    { label: 'PHD-LICENSED PROFESSIONAL COUNSELOR' },
    { label: 'PHD-PHYSICIAN' },
    { label: 'PHD-PSYCHIATRIST' },
    { label: 'PHD-PSYCHOLOGIST' },
    { label: 'PHM-PHYSICIAN' },
    { label: 'PHO-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PHO-PHYSICIAN' },
    { label: 'PHO-REGISTERED NURSE PRACTITIONER' },
    { label: 'PIP-PHYSICIAN' },
    { label: 'PLM-HOSPICE' },
    { label: 'PLM-PHYSICIAN' },
    { label: 'PMD-OSTEOPATH' },
    { label: 'PMD-PHYSICIAN' },
    { label: 'PM-GROUP OF PROVIDERS' },
    { label: 'PM-OSTEOPATH' },
    { label: 'PM-PHYSICAL THERAPIST' },
    { label: 'PM-PHYSICIAN' },
    { label: 'PM-REGISTERED NURSE PRACTITIONER' },
    { label: 'PN-DIALYSIS' },
    { label: 'PNF-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'PN-PHYSICIAN' },
    { label: 'POD-PHYSICIAN' },
    { label: 'POD-PODIATRIST' },
    { label: 'PO-PHYSICIAN' },
    { label: 'P-OSTEOPATH' },
    { label: 'P-PHYSICAL THERAPIST' },
    { label: 'P-PHYSICIAN' },
    { label: 'PP-OSTEOPATH' },
    { label: 'PP-PHYSICIAN' },
    { label: 'PPR-PHYSICIAN' },
    { label: 'P-PSYCHIATRIST' },
    { label: 'P-PSYCHOLOGIST' },
    { label: 'PR-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'P-REGISTERED NURSE PRACTITIONER' },
    { label: 'PR-LICENSED PRACTICAL NURSE' },
    { label: 'PR-MASTER OF SCIENCE IN NURSING' },
    { label: 'PRM-PHYSICIAN' },
    { label: 'PR-OCCUPATIONAL THERAPIST' },
    { label: 'PRO-PSYCHOLOGIST' },
    { label: 'PR-PEDIATRIC NURSE PRACTITIONER' },
    { label: 'PR-PHYSICIAN' },
    { label: 'PR-REGISTERED NURSE (PRIVATE NURSE)' },
    { label: 'PR-REGISTERED NURSE PRACTITIONER' },
    { label: 'PSA-GROUP OF PROVIDERS' },
    { label: 'PSA-NURSE MIDWIFE' },
    { label: 'PSA-PHYSICIAN' },
    { label: 'PSA-PHYSICIAN ASSISTANT' },
    { label: 'PSA-REGISTERED NURSE PRACTITIONER' },
    { label: 'PS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PS-MASTER OF SCIENCE IN NURSING' },
    { label: 'P-SOCIAL WORKER' },
    { label: 'PS-PHYSICIAN' },
    { label: 'PS-PHYSICIAN ASSISTANT' },
    { label: 'PS-PODIATRIST' },
    { label: 'PS-REGISTERED NURSE PRACTITIONER' },
    { label: 'PT-DOCTOR OF PHYSICAL THERAPY' },
    { label: 'PT-GROUP OF PROVIDERS' },
    { label: 'PTH-GROUP OF PROVIDERS' },
    { label: 'PTH-LABORATORY' },
    { label: 'PTH-PHYSICIAN' },
    { label: 'PT-OCCUPATIONAL THERAPIST' },
    { label: 'PT-PHYSICAL THERAPIST' },
    { label: 'PT-PHYSICIAN' },
    { label: 'PT-PHYSICIAN ASSISTANT' },
    { label: 'PUD-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'PUD-GROUP OF PROVIDERS' },
    { label: 'PUD-PHYSICIAN' },
    { label: 'PUD-PHYSICIAN ASSISTANT' },
    { label: 'PYG-PHYSICIAN' },
    { label: 'R-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'REN-PHYSICIAN' },
    { label: 'R-GROUP OF PROVIDERS' },
    { label: 'RHU-MASTER OF SCIENCE IN NURSING' },
    { label: 'RHU-PHYSICIAN' },
    { label: 'RHU-REGISTERED NURSE PRACTITIONER' },
    { label: 'RIP-PHYSICIAN' },
    { label: 'R-LABORATORY' },
    { label: 'RNR-PHYSICIAN' },
    { label: 'RO-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'RO-GROUP OF PROVIDERS' },
    { label: 'RO-PHYSICIAN' },
    { label: 'R-PHYSICIAN' },
    { label: 'R-PHYSICIAN ASSISTANT' },
    { label: 'R-REGISTERED NURSE PRACTITIONER' },
    { label: 'RTC-GROUP OF PROVIDERS' },
    { label: 'RTC-RESIDENTIAL TREATMENT FACILITY' },
    { label: 'RT-RESIDENTIAL TREATMENT FACILITY' },
    { label: 'SCI-PHYSICIAN' },
    { label: 'SH-PHYSICIAN' },
    { label: 'SH-SPEECH LANGUAGE PATHOLOGIST' },
    { label: 'SLP-ANCILLARY' },
    { label: 'SLP-AUDIOLOGIST' },
    { label: 'SLP-DOCTOR OF AUDIOLOGY' },
    { label: 'SLP-GROUP OF PROVIDERS' },
    { label: 'SLP-PHYSICIAN' },
    { label: 'SLP-SERVICE LOCATION' },
    { label: 'SLP-SOCIAL WORKER' },
    { label: 'SLP-SPEECH LANGUAGE PATHOLOGIST' },
    { label: 'SLP-SPEECH LANGUAGE PATHOLOGY' },
    { label: 'SLP-SPEECH/HEARING THERAPIST' },
    { label: 'SM-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'SM-PHYSICIAN' },
    { label: 'SM-SOCIAL WORKER' },
    { label: 'SO-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'SO-PHYSICIAN' },
    { label: 'ST-GROUP OF PROVIDERS' },
    { label: 'ST-SPEECH LANGUAGE PATHOLOGIST' },
    { label: 'ST-SPEECH LANGUAGE PATHOLOGY' },
    { label: 'ST-SPEECH/HEARING THERAPIST' },
    { label: 'SU-DOCTOR OF PHYSICAL THERAPY' },
    { label: 'TRS-OSTEOPATH' },
    { label: 'TRS-PHYSICIAN' },
    { label: 'TRS-REGISTERED NURSE PRACTITIONER' },
    { label: 'TS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'TS-PHYSICIAN' },
    { label: 'TTS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'TTS-PHYSICIAN' },
    { label: 'TTS-REGISTERED NURSE PRACTITIONER' },
    { label: 'U-OSTEOPATH' },
    { label: 'U-PHYSICIAN' },
    { label: 'U-PHYSICIAN ASSISTANT' },
    { label: 'UP-PHYSICIAN' },
    { label: 'U-REGISTERED NURSE PRACTITIONER' },
    { label: 'US-GROUP OF PROVIDERS' },
    { label: 'US-PHYSICIAN' },
    { label: 'VIR-OSTEOPATH' },
    { label: 'VIR-PHYSICIAN' },
    { label: 'VIR-REGISTERED NURSE PRACTITIONER' },
    { label: 'VS-CERTIFIED REGISTERED NURSE PRACTITIONER' },
    { label: 'VS-PHYSICIAN' },
    { label: 'VS-PHYSICIAN ASSISTANT' },
    { label: 'VS-REGISTERED NURSE PRACTITIONER' },
    { label: 'ZZ-ADULT DAY HEALTH' },
    { label: 'ZZ-ANCILLARY' },
    { label: 'ZZ-APPLIED BEHAVIORAL ANALYST' },
    { label: 'ZZ-AUDIOLOGIST' },
    { label: 'ZZ-CASE MANAGER' },
    { label: 'ZZ-CERTIFIED REGISTERED NURSE ANESTHETIST' },
    { label: 'ZZ-CHIROPRACTOR' },
    { label: 'ZZ-CLINIC, ADLT Alc/SUBST ABUSE' },
    { label: 'ZZ-COMMUNITY/RURAL HEALTH CENTER' },
    { label: 'ZZ-DENTIST' },
    { label: 'ZZ-DIALYSIS' },
    { label: 'ZZ-DME SUPPLIER' },
    { label: 'ZZ-DRUG AND ALCOHOL REHAB' },
    { label: 'ZZ-EARLY INTERVENTION' },
    { label: 'ZZ-EMERGENCY TRANSPORTATION' },
    { label: 'ZZ-GROUP OF PROVIDERS' },
    { label: 'ZZ-HEALTH EDUCATOR' },
    { label: 'ZZ-HOME CARE' },
    { label: 'ZZ-HOME HEALTH AGENCY' },
    { label: 'ZZ-HOME HEALTH AIDE' },
    { label: 'ZZ-HOME MODIFICATION PROVIDER' },
    { label: 'ZZ-HOSPICE' },
    { label: 'ZZ-HOSPITAL' },
    { label: 'ZZ-HOSPITAL AFFILIATED CLINIC' },
    { label: 'ZZ-IN HOME THERAPY' },
    { label: 'ZZ-LABORATORY' },
    { label: 'ZZ-LICENSED PRACTICAL NURSE' },
    { label: 'ZZ-LICENSED PROFESSIONAL COUNSELOR' },
    { label: 'ZZ-MENTAL HEALTH CLINIC' },
    { label: 'ZZ-MENTAL HEALTH RESIDENTIAL TREATMENT CENTER' },
    { label: 'ZZ-NATUROPATH' },
    { label: 'ZZ-NO PROVIDER TYPE' },
    { label: 'ZZ-NON-EMERGENCY TRANSPORTATION PROVIDERS' },
    { label: 'ZZ-NURSE AIDE' },
    { label: 'ZZ-NURSE MIDWIFE' },
    { label: 'ZZ-NUTRITIONIST' },
    { label: 'ZZ-OCCUPATIONAL THERAPIST' },
    { label: 'ZZ-OPTOMETRIST' },
    { label: 'ZZ-OSTEOPATH' },
    { label: 'ZZ-PERSONAL CARE ASSISTANT' },
    { label: 'ZZ-PERSONAL CARE ATTENDANT' },
    { label: 'ZZ-PHARMACY' },
    { label: 'ZZ-PHO CONTRACT' },
    { label: 'ZZ-PHYSICAL THERAPIST' },
    { label: 'ZZ-PHYSICIAN' },
    { label: 'ZZ-PHYSICIAN ASSISTANT' },
    { label: 'ZZ-PODIATRIST' },
    { label: 'ZZ-PSYCHIATRIC HOSPITAL' },
    { label: 'ZZ-PSYCHIATRIST' },
    { label: 'ZZ-PSYCHOLOGIST' },
    { label: 'ZZ-REGISTERED BEHAVIOR TECHNICIAN' },
    { label: 'ZZ-REGISTERED DIETICIAN' },
    { label: 'ZZ-REGISTERED NURSE (PRIVATE NURSE)' },
    { label: 'ZZ-REGISTERED NURSE PRACTITIONER' },
    { label: 'ZZ-RESIDENTIAL TREATMENT FACILITY' },
    { label: 'ZZ-RESPITE' },
    { label: 'ZZ-SERVICE LOCATION' },
    { label: 'ZZ-SKILLED NURSING FACILITY' },
    { label: 'ZZ-SLEEP CENTER' },
    { label: 'ZZ-SOCIAL WORKER' },
    { label: 'ZZ-SPEECH LANGUAGE PATHOLOGIST' },
    { label: 'ZZ-SPEECH LANGUAGE PATHOLOGY' },
    { label: 'ZZ-SPEECH/HEARING THERAPIST' },
    { label: 'ZZ-URGENT CARE CENTER' },
    { label: 'ZZ-VISION CENTER' },
]