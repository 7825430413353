import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
//import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId:  process.env.REACT_APP_SSO_CLIENT_ID,
    authority: process.env.REACT_APP_SSO_URL,
    redirectUri: "/",
  },
});

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    console.log(event);
    msalInstance.setActiveAccount(event.payload.account);
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <BrowserRouter>    <MsalProvider instance={msalInstance}><App msalInstance={msalInstance} /> </MsalProvider></BrowserRouter>
  </React.StrictMode>
);
