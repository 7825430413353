import React from "react";
import Button from '@mui/material/Button';
import { useMsal } from "@azure/msal-react";

const AuthLogout = () => {

    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        //            account: instance.getAccountByHomeId(homeAccountId),
        localStorage.clear();
        const logoutRequest = {
            postLogoutRedirectUri: "/",
          };
        instance.logoutRedirect(logoutRequest)
    } 
  
  return (
    <Button color="inherit" onClick={() => handleLogout("redirect")}>Sign Out</Button>
    )
};

export default AuthLogout;