import React from "react";
import Button from '@mui/material/Button';
import { useMsal } from "@azure/msal-react";
//import { useMsal, useIsAuthenticated } from "@azure/msal-react";
//import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

const AuthLogin = () => {
    const { instance } = useMsal();
    //const { instance, inProgress } = useMsal();    
    //const isAuthenticated = useIsAuthenticated();

    const handleAzureLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            console.log("login");
           // if (!isAuthenticated && inProgress === InteractionStatus.None) {
                instance.loginRedirect(loginRequest).catch(e => {
                    console.log(e);
                });
          //  }
        }
    }

    return (
        <Button color="inherit" onClick={() => handleAzureLogin("redirect")}>Sign In</Button>
      );

    };

    export default AuthLogin;