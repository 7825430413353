import { useState, useEffect } from "react"
import "../pages/table.css"
import { FooterCell } from "../pages/FooterCell"

import FilterListIcon from '@mui/icons-material/FilterListTwoTone';
import FilterDialog from "../components/Filter"
import Button from '@mui/material/Button';

import { useMsal } from "@azure/msal-react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table"

const defaultData = []

export const HedisTable = ({columns, dbtable, title}) => {
  const [data, setData] = useState(() => [...defaultData])
  const [originalData, setOriginalData] = useState(() => [...defaultData])
  const [editedRows, setEditedRows] = useState({})
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false);
  const [selectedFilterValue, setFilterValue] = useState('');

  //const [columnFilters, setColumnFilters] = useState([])

  const url = process.env.REACT_APP_API_URL + "?table=" + dbtable;

  const { instance, accounts } = useMsal();

  const [token, setToken] = useState('');
  const accessTokenRequest = {
    scopes: [process.env.REACT_APP_API_SCOPE],
    account: accounts[0],
  };

  useEffect(() => {
    async function getAccessToken(){
      
      //const account = msalInstance.getActiveAccount();
      //const accessTokenRequest = {
      //    scopes: ["https://<my api uri>/api.full_access"],
      //    account: account,
      //}
      await instance.initialize(); // Add this line
      var response = await instance.acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        let accessToken = response.accessToken;
        console.log("HERE GET API ACCESS TOKEN", accessToken)
  
        setToken(accessToken);
  
        console.log(url);

        const headers = { headers: { 'Authorization': `Bearer ${accessToken}`, 'x-api-key': process.env.REACT_APP_API_KEY } }
        setLoading(true)
        //console.log("HEADER", headers)
        fetch(url, headers)    
        .then(response => response.json())
        .then(data => { 
          const rt = [...data]
          setData(rt)
          setOriginalData(rt)
        })
        .finally(() => {
          setLoading(false)
        })
      })
 
      return response;
  }
/*
* Now use the above function instead of this one. MSAL Documents recommend using await and async
* So just keepoing this around for reference. 
    async function getToken() {
      instance.setActiveAccount(accounts[0]);
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          console.log("GET API ACCESS TOKEN", accessToken)

          setToken(accessToken);

          const headers = { headers: { 'Authorization': `Bearer ${accessToken}`, 'x-api-key': process.env.REACT_APP_API_KEY } }
          setLoading(true)
          //console.log("HEADER", headers)
          fetch(url, headers)    
          .then(response => response.json())
          .then(data => { 
            const rt = [...data]
            setData(rt)
            setOriginalData(rt)
          })
          .finally(() => {
            setLoading(false)
          })

        })
      }
*/      
      if (token.length<1) {
        console.log("TOKEN:", token)
        getAccessToken();
       //getToken();
       //setLoading(false)
    }
    //console.log("AT", apiAccessToken)

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleFilterClose = (value) => {
    setOpen(false);
    setFilterValue(value);
    setLoading(true)
    instance
          .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
              // Acquire token silent success
              let accessToken = accessTokenResponse.accessToken;
              // Call API with token       
              const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`, 'x-api-key': process.env.REACT_APP_API_KEY },
              };
      
              let filterUrl = url + '&filter=' + value;

              fetch(filterUrl, requestOptions)
                .then(response => response.json())
                .then(data => { 
                  const rt = [...data]
                  setData(rt)
                  setOriginalData(rt)
                })
                .finally(() => {     
                  setLoading(false)
                })
          })             
  }

  const [validRows, setValidRows] = useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editedRows,
      setEditedRows,
      validRows,
      setValidRows,
      revertData: (rowIndex, revert) => {
        if (revert) {
          setData(old =>
            old.map((row, index) =>
              index === rowIndex ? originalData[rowIndex] : row
            )
          )
        } else {
          setOriginalData(old =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
          )
        }
      },
      addRow: () => {
        const newRow = {
          application: "hedis",
          created_by: accounts[0]?.username
        }
        const uu = table.getAllColumns();
        Object.keys(uu).forEach(function(key) {
          console.log(uu[key].id);
          let keyval = uu[key].id;
          if (keyval != "edit") {
            setValidRows((old) => ({
              ...old,
              [data.length]: { ...old[data.length], [keyval]: false },
            }));
          }
        });

        const setFunc = old => [...old, newRow];

        setData(setFunc);
        setOriginalData(setFunc);

        setEditedRows(old => ({
          ...old,
          [data.length]: !old[data.length]
        }))
      },
      removeRow: (rowIndex, ask=true) => {
        if (ask) {
          if (window.confirm("Are you sure you wish to delete record?"))
          { 
                //console.log(data[rowIndex])
                console.log("DELETE")
                const dtable = {datatable: dbtable, action: 'delete', data: data[rowIndex]}
                instance
                  .acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                      // Acquire token silent success
                      let accessToken = accessTokenResponse.accessToken;
                      // Call API with token       

                      const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`, 'x-api-key': process.env.REACT_APP_API_KEY },
                        body: JSON.stringify(dtable)
                      };
              
                      fetch(process.env.REACT_APP_API_URL, requestOptions)
                        .then(response => response.json())
                        .then(data => console.log(data));              
        
                    const setFilterFunc = old => old.filter((_row, index) => index !== rowIndex)
                    setData(setFilterFunc)
                    setOriginalData(setFilterFunc)
                  })
          }
        }
        else {
          const setFilterFunc = old => old.filter((_row, index) => index !== rowIndex)
          setData(setFilterFunc)
          setOriginalData(setFilterFunc)
        }
      },           
      updateData: (rowIndex, columnId, value, isValid) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              };
            }
            return row;
          })
        );
        setValidRows((old) => ({
          ...old,
          [rowIndex]: { ...old[rowIndex], [columnId]: isValid },
        }));
      }
    }
  })
  return (
    <>
<div style={{height: "72px"}}>
      <div className="col-md-12 text-left f-l" >
      <h2 style={{textAlign: "left", paddingLeft: "15px"}}>{title}</h2>
      </div>
                                                                    
      <div className="col-md-12 text-right f-r" >
          <div style={{width: "100px", paddingRight: "5px", border: "1px", borderColor: "red"}}>{selectedFilterValue}</div>
          <Button id="next_button" variant="contained" onClick={handleClickOpen} 
          ><FilterListIcon /> Filter</Button>
        
        <FilterDialog
          selectedValue={selectedFilterValue}
          open={open}
          onClose={handleFilterClose}
        />
      </div>
</div>
    <div className="table-container">
    {loading ? (
            <div className="spinner-container">
            <div className="loading-spinner">
            </div>
          </div>
      ) : (
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={table.getCenterLeafColumns().length} align="right">
              <FooterCell table={table} />
            </th>
          </tr>
        </tfoot>        
      </table>
      )}
    </div>  
      <pre style={{textAlign: "left"}}>{process.env.REACT_APP_SHOW_JSON_DEBUG ? (data.length > 0 ? JSON.stringify(data, null, "\t") : "") : ""}</pre>
    </>
  )
}


